import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectProfile } from "src/store/profile/profileSlice";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import BhiTable from "src/components/Table/view";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import { DefaultCustomField, MessageTemplate } from "src/constants/types";
import { GridColDef } from "@mui/x-data-grid";
import {
  useDeleteMessageTemplateApi,
  useGetMessageTemplateApi,
  useGetMessageTemplatesApi,
} from "src/api/messageTemplates";
import { selectedCompany } from "src/store/company/companySlice";
import { useGetDefaultCustomFieldsValuesApi } from "src/api/customFields";
import dayjs from "dayjs";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const MessageTemplates = () => {
  const { profile } = useSelector(selectProfile);
  const selectedCompanyId = useSelector(selectedCompany);
  const getMetadata = useGetDefaultCustomFieldsValuesApi();
  const [metadata, setMetadata] = useState<DefaultCustomField[]>();
  const getMessageTemplates = useGetMessageTemplatesApi();
  const getMessageTemplate = useGetMessageTemplateApi();
  const deleteMessageTemplate = useDeleteMessageTemplateApi();

  useHandleSelectedCompany();

  useEffect(() => {
    if (selectedCompanyId) {
      const params = {
        companyId: selectedCompanyId,
        limit: 10000,
        offset: 0,
        types: "contact,company",
      };
      getMetadata(params)
        .then((data) => {
          setMetadata(data.data.items);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedCompanyId]);

  const initialMessageTemplate: MessageTemplate = {
    name: "",
    text: "",
  };

  const formatColumns = (
    handleEditItem: (id: string) => void,
  ) => {
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Name",
        width: 150,
        sortable: true,
        editable: false,
      },
      {
        field: "text",
        headerName: "Template",
        sortable: false,
        width: 450,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="column" height="100%" justifyContent="center">
              <Typography
                sx={{
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "dateCreated",
        headerName: "Created",
        width: 150,
        sortable: true,
        editable: false,
        renderCell: (row) => {
          return (
            <Box>
              {" "}
              {row.formattedValue
                ? dayjs(row.formattedValue).format("YYYY-MM-DD hh:mm A")
                : ""}
            </Box>
          );
        },
      },
      {
        field: "dateUpdated",
        headerName: "Updated",
        sortable: true,
        width: 150,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue
                  ? dayjs(row.formattedValue).format("YYYY-MM-DD hh:mm A")
                  : ""}
              </Box>
              <Box
                className="bhiTableActions"
                sx={{
                  position: "absolute",
                  right: "5px",
                  zIndex: 99,
                  display: "none",
                }}
              >
                {profile && profile?.role?.name === "company_user" && (
                  <>
                    <IconButton
                      sx={{
                        color: "#264597",
                        borderRadius: "50%",
                        marginLeft: "10px",
                        transform: "scale(0.7)",
                        backgroundColor: "transparent",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditItem(row.row.id);
                      }}
                    >
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Stack>
          );
        },
      },
    ];

    return columns;
  };

  if (!profile) {
    return null;
  }

  return (
    <>
      <BhiTable<MessageTemplate>
        metadata={metadata}
        selectedCompanyId={selectedCompanyId}
        tableName="messageTemplates"
        formatColumns={formatColumns}
        fetchData={getMessageTemplates}
        getItem={getMessageTemplate}
        deleteItem={deleteMessageTemplate}
        initialData={initialMessageTemplate}
        allowActions={profile && profile?.role?.name !== "company_user"}
        pageSize={100}
      />
    </>
  );
};

export default MessageTemplates;
