import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { Label } from "./styles";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useAddBulkContactsApi } from "src/api/contacts";
import { selectedCompany } from "src/store/company/companySlice";
import { useSelector } from "react-redux";
import {
  generateSummary,
  getCreatedContactsIds,
  getExistedContactsIds,
} from "./helper";
import { ImportContactsModalStep } from "../costants";
//import { useGetCustomFieldsApi } from "src/api/customFields";
//import { CustomField } from "src/constants/types";
import DownloadCSV from "./DownloadCSV";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type DownloadCsvModalType = {
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setCreatedContactsIds: React.Dispatch<React.SetStateAction<string[]>>;
  setExistedContactsIds: React.Dispatch<React.SetStateAction<string[]>>;
  setImportContactsStep: React.Dispatch<
    React.SetStateAction<ImportContactsModalStep>
  >;
};

//const customFieldsLimit = 10000;

export default function ImportContacts({
  error,
  setError,
  setCreatedContactsIds,
  setExistedContactsIds,
  setImportContactsStep,
}: DownloadCsvModalType) {
  const [response, setResponse] = useState<string>("");
  const selectedCompanyId = useSelector(selectedCompany);
  const [isAccepting, setIsAccepting] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const uploadCsvApi = useAddBulkContactsApi();
  const [isContactsUploading, setIsContactsUploading] = useState(false);

  const formatHeaders = () => {
    const fields = "Phone number,First name,Last name,Email,Birthdate,";
    return fields.replace(/,\s*$/, "");
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const file = event.target?.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const addCsvContacts = async () => {
    const formData = new FormData();
    file && formData.append("file", file);
    if (formData && selectedCompanyId) {
      setIsContactsUploading(true);
      uploadCsvApi(formData, selectedCompanyId)
        .then(({ data }) => {
          setResponse(generateSummary(data));
          const createdContactsIds = getCreatedContactsIds(
            data.createdContacts
          );
          if (createdContactsIds && createdContactsIds.length > 0) {
            setCreatedContactsIds(createdContactsIds);
            setError(false);
          }
          const existedContactsIds = getExistedContactsIds(
            data.existedContacts
          );
          if (existedContactsIds && existedContactsIds.length > 0) {
            setExistedContactsIds(existedContactsIds);
            setError(false);
          }

          if (
            (existedContactsIds && existedContactsIds.length > 0) ||
            (createdContactsIds && createdContactsIds.length > 0)
          ) {
            setImportContactsStep(ImportContactsModalStep.addToGroups);
          }
        })
        .catch((e) => {
          if (e.error === "validation") {
            setError(true);
          }
        })
        .finally(() => {
          setIsContactsUploading(false);
        });
    }
  };

  return (
    <Fragment>
      <span style={{ marginBottom: "20px" }}>
        {"Click "}
        <DownloadCSV data={formatHeaders()} fileName="Contact-CSV-example" />
        {/* <Link color="primary" href={downloadUrl} target="_blank">
          here
        </Link> */}
        {` to download the template for importing contacts.`}
      </span>
      <Button
        component="label"
        style={{ width: "250px" }}
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput
          type="file"
          onChange={handleFileUpload}
          accept=".csv"
        />
      </Button>
      {file?.name && <Label>{`File: ${file?.name}`}</Label>}
      {response && <Label>{response}</Label>}
      {error && (
        <FormLabel
          error={true}
          style={{ marginTop: "10px", textAlign: "center" }}
        >
          {
            "Unable to import contacts. Please check the file and data to ensure it matches the required format."
          }
        </FormLabel>
      )}
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => setIsAccepting(event.target.checked)}
          />
        }
        style={{ marginTop: "30px", wordSpacing: "no-wrap" }}
        label="I confirm that I have received express consent to message the contacts that I am adding."
      />
      <Button
        variant="contained"
        style={{
          width: "150px",
          textAlign: "center",
          alignSelf: "flex-end",
        }}
        onClick={addCsvContacts}
        disabled={!file || !isAccepting || isContactsUploading}
      >
        {isContactsUploading ? <CircularProgress size={25} /> : "Import"}
      </Button>
    </Fragment>
  );
}
