import React, { useState, useContext } from 'react';
import { LoginContainer, Container, Header } from './styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useLoginApi } from 'src/api/authentication';
import { AuthContext } from 'src/contexts/AuthContext';
import { useNavigate } from "react-router-dom";
import { loginValidationSchema, ValidationErrors } from 'src/constants/validations';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as Yup from 'yup';

function Login() {
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showPassword, setShowPassword] = useState(false);

  const authContext = useContext(AuthContext);
  const login = useLoginApi();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = async (values: typeof formValues) => {
    try {
      await loginValidationSchema.validate(values, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleLogin = async () => {
    const isValid = await validate(formValues);
    if (isValid) {
      login(formValues).then(({ data }) => {
        authContext?.setIsAuthenticated(true);
        localStorage.setItem('a_token', data.accessToken);
        localStorage.setItem('r_token', data.refreshToken);
        navigate('/home');
      }).catch((e) => {
        if (e.error === 'validation') {
          setErrors({validation: e.message})
        } else {
          navigate('/access-denied');
        }
      });
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement> ): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleLogin();
    }
  }

  return <Container>
    <LoginContainer>
      <Header>Welcome to AgencyText</Header>
      <TextField
        error={!!errors?.email}
        id="outlined-basic"
        helperText={errors?.email || ''}
        label="Email Address"
        variant="outlined"
        value={formValues.email}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        name="email"
      />
      <FormControl variant="outlined" style={{ margin: '18px 0px' }}>
        <InputLabel htmlFor="outlined-adornment-password" style={errors?.password ? {color: '#d32f2f'} : {}} >Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          value={formValues.password}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          name="password"
          error={!!errors?.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        <FormHelperText id="component-error-text" style={{color: '#d32f2f'}}>{errors.password || ''}</FormHelperText>
        <FormHelperText id="component-error-text" style={{color: '#d32f2f'}}>{errors.validation || ''}</FormHelperText>
      </FormControl>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          navigate('/forgot-password')
        }}
        style={{ marginBottom: '24px', textAlign: 'right' }}
      >
        Forgot Password?
      </Link>
      <Button type='submit' variant="contained" size="large" onClick={handleLogin}>
        Login
      </Button>
    </LoginContainer>
  </Container>
}

export default Login;
