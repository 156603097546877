import {
  Box,
  Button,
  IconButton,
  Popper,
  Stack,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CreateAdminCompanyModal from "src/pages/Companies/CreateCompanyModal/view";
import {
  AdminCompany,
  DefaultCustomField,
  MessageTemplate,
} from "src/constants/types";
import CreateMessageTemplateModal from "src/pages/Settings/Components/MessageTemplates/CreateMessageTemplateModal/view";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import useConfirmationDialog from "../ConfirmationDialog/view";
import { useSelector } from "react-redux";
import { selectProfile } from "src/store/profile/profileSlice";

interface IProps<TEntity> {
  selectedCompanyId?: string;
  tableName: "companies" | "messageTemplates";
  fetchData: (
    params: object
  ) => Promise<{ data: { items: TEntity[]; count: number } }>;
  getItem: (id: string, companyId?: string) => Promise<{ data: TEntity }>;
  deleteItem: (id: string, companyId?: string) => Promise<void>;
  pageSize: number;
  initialData: TEntity;
  metadata?: DefaultCustomField[];
  allowActions?: boolean;
  formatColumns: (handleEditItem: (id: string) => void) => GridColDef[];
}

function BhiTable<TEntity>({
  fetchData,
  getItem,
  deleteItem,
  pageSize = 5,
  initialData,
  formatColumns,
  tableName,
  selectedCompanyId,
  metadata,
  allowActions,
}: IProps<TEntity>) {
  const { profile } = useSelector(selectProfile);

  const handleItemEdit = (id: string) => {
    if (selectedCompanyId) {
      getItem(id, selectedCompanyId).then((response) => {
        setData(response.data);
        setCreateModal(true);
      });
    } else {
      getItem(id).then((response) => {
        setData(response.data);
        setCreateModal(true);
      });
    }
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      status: false,
    });

  const handleItemDelete = (id: string) => {
    if (selectedCompanyId) {
      deleteItem(id, selectedCompanyId).then(loadData);
    } else {
      deleteItem(id).then(loadData);
    }
  };

  const columns = formatColumns(handleItemEdit);
  const [rows, setRows] = useState<TEntity[]>();
  const [data, setData] = useState<TEntity>(initialData);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [showCreateModal, setCreateModal] = useState(false);
  const navigate = useNavigate();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();

  const anchorEl = useRef<HTMLElement | null>(null);
  const [rowId, setRowId] = useState<string>();
  // const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: pageSize,
    });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  const getQueryParams = () => {
    const params: {
      limit: number;
      offset: number;
      sort: string;
      query: string | undefined;
      companyId?: string;
    } = {
      limit: pageSize,
      offset: paginationModel.page * pageSize,
      sort: `${sortModel[0].field}_${sortModel[0].sort}`,
      query: searchQuery,
    };

    if (selectedCompanyId) {
      params.companyId = selectedCompanyId;
    }

    if (!searchQuery) {
      delete params.query;
    }

    return params;
  };

  const loadData = async () => {
    setIsLoading(true);
    const response = await fetchData(getQueryParams());
    setRows(response.data.items);
    setTotalCount(response.data.count);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [paginationModel, sortModel, searchQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.parentElement!.dataset.id!;
    setRowId(id);
    anchorEl.current = event.currentTarget.parentElement;
  };

  const deleteSubtitle =
    tableName === "companies" ? "Company" : "Message Template";
  return (
    <>
      <Box sx={{ paddingTop: "20px;", position: "relative" }}>
        <Stack
          direction={"row"}
          sx={{
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            border: "1px solid #F0F0F0",
            padding: "15px",
          }}
          justifyContent={"space-between"}
          height="40px"
        >
          <TextField
            size="small"
            onChange={handleChange}
            InputProps={{ endAdornment: <SearchIcon /> }}
          />
          {(tableName === "companies" ||
            (tableName === "messageTemplates" &&
              profile &&
              profile?.role?.name !== "company_user")) && (
            <Button
              sx={{ height: "40px;" }}
              size="small"
              variant="contained"
              onClick={() => {
                setData(initialData);
                setCreateModal(true);
              }}
            >
              {tableName === "companies" && `Create Company`}
              {tableName === "messageTemplates" && `Add New Template`}
            </Button>
          )}
          {tableName === "companies" && (
            <CreateAdminCompanyModal
              initialCompany={initialData as AdminCompany}
              adminCompany={data as AdminCompany}
              show={showCreateModal}
              setShowModal={setCreateModal}
              refreshCompanies={loadData}
            />
          )}
          {tableName === "messageTemplates" && (
            <CreateMessageTemplateModal
              metadata={metadata}
              initialMessageTemplate={initialData as MessageTemplate}
              messageTemplate={data as MessageTemplate}
              show={showCreateModal}
              setShowModal={setCreateModal}
              refreshMessageTemplates={loadData}
            />
          )}
        </Stack>
        <Box sx={{ position: "relative" }}>
          <DataGrid
            slotProps={{
              cell: {
                onMouseEnter: handlePopoverOpen,
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            columns={columns}
            rows={rows}
            pagination
            disableMultipleRowSelection
            disableRowSelectionOnClick
            pageSizeOptions={[pageSize]}
            sortingMode="server"
            paginationMode="server"
            paginationModel={paginationModel}
            sortModel={sortModel}
            disableColumnFilter
            sortingOrder={["asc", "desc"]}
            rowSelection={false}
            rowCount={totalCount}
            loading={isLoading}
            onPaginationModelChange={(
              newPaginationModel: GridPaginationModel
            ) => {
              setPaginationModel(newPaginationModel);
            }}
            onSortModelChange={(sortModel) => {
              setSortModel(sortModel);
            }}
            sx={{
              fontSize: "12px",
              padding: 0,
              height: "calc(100vh - 250px)",
              "&, [class=MuiDataGrid]": {
                borderTop: "none",
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
              },
              "& .MuiDataGrid-columnSeparator": {
                opacity: "1 !important;",
              },
              "& .MuiDataGrid-container--top [role=row]": {
                backgroundColor: "rgb(37, 69, 151)",
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: 1,
                color: "white",
              },
              "& .MuiDataGrid-menuIconButton": {
                opacity: 1,
                color: "white",
              },
              "& .MuiDataGrid-columnHeader[data-field=subscription.autorenew] .MuiDataGrid-columnSeparator--sideRight, .MuiDataGrid-columnHeader[data-field=actions] .MuiDataGrid-columnSeparator--sideRight":
                {
                  display: "none",
                },
              "& .MuiDataGrid-columnSeparator:hover": {
                color: "white",
              },
            }}
          />
          {allowActions && (
            <Popper open={open} placement="right" anchorEl={anchorEl.current}>
              <Box
                sx={{
                  position: "fixed",
                  width: "90px",
                  right: "-10px",
                  top: "-20px",
                }}
              >
                <IconButton
                  sx={{
                    cursor: "pointer",
                    color: "#264597",
                    borderRadius: "50%",
                    marginLeft: "0px",
                    transform: "scale(0.7)",
                    backgroundColor: "transparent",
                    zIndex: 999999,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (tableName === "messageTemplates") {
                      handleItemEdit(rowId!);
                    }
                    if (tableName === "companies") {
                      navigate(`/admin/${tableName}/edit/${rowId}`);
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{
                    cursor: "pointer",
                    color: "#264597",
                    transform: "scale(0.7)",
                    borderRadius: "50%",
                    zIndex: 999999,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDialog({
                      title: `Delete ${deleteSubtitle}`,
                      content: `Are you sure you want to delete this ${deleteSubtitle}?`,
                      onConfirm: () => {
                        if (rowId) {
                          handleItemDelete(rowId!);
                        }
                      },
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Popper>
          )}
        </Box>
      </Box>
      <ConfirmationDialog />
    </>
  );
}

export default BhiTable;
