import { CreateAdminCompanyApiParams, CreateAdminCompanyPhoneNumberApiParams, CreateAdminCompanySubscriptionApiParams, DeleteAdminCompanyApiParams, DeleteAdminCompanyPhoneNumberApiParams, DeleteAdminCompanySubscriptionApiParams, GetAdminApplicationsApiParams, GetAdminCompaniesApiParams, GetAdminCompanyApiParams, GetAdminCompanyMetricsApiParams, GetAdminCompanyPhoneNumbersApiParams, GetAdminPlansApiParams, GetAdminSubscriptionsApiParams, UpdateAdminCompanyApiParams, UpdateAdminCompanyPhoneNumberApiParams, UpdateAutoRenewStatusOfCompanySubscriptionApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

export function useGetAdminCompaniesApi(): GetAdminCompaniesApiParams {
  return useCallback(params => apiClient.get(`/companies`, { params }), []);
}

export function useGetAdminApplicationsApi(): GetAdminApplicationsApiParams {
  return useCallback(params => apiClient.get(`/applications`, { params }), []);
}

export function useGetAdminPlansApi(): GetAdminPlansApiParams {
  return useCallback(params => apiClient.get(`/plans`, { params }), []);
}

export function useGetAdminSubscriptionsApi(): GetAdminSubscriptionsApiParams {
  return useCallback(params => apiClient.get(`/subscriptions`, { params }), []);
}

export function useCreateAdminCompanySubscriptionApi(): CreateAdminCompanySubscriptionApiParams {
  return useCallback((companyId, planId, startDate, autoRenew) => apiClient.post(`subscriptions`, {companyId, planId, startDate, autoRenew}), []); 
}

export function useDeleteAdminCompanySubscriptionApi(): DeleteAdminCompanySubscriptionApiParams {
  return useCallback((subscriptionId) => apiClient.delete(`subscriptions/${subscriptionId}`), []);
}

export function useGetAdminCompanyApi(): GetAdminCompanyApiParams {
  return useCallback((companyId) => apiClient.get(`/companies/${companyId}`), []);
}

export function useGetAdminCompanyMetricsApi(): GetAdminCompanyMetricsApiParams {
  return useCallback((params) => apiClient.get(`/companies/metrics`, { params }), []);
}

export function useCreateAdminCompanyApi(): CreateAdminCompanyApiParams {
  return useCallback((params) => apiClient.post(`/companies`, params), []);
}

export function useDeleteAdminCompanyApi(): DeleteAdminCompanyApiParams {
  return useCallback((companyId) => apiClient.delete(`companies/${companyId}`), []);
}

export function useUpdateAdminCompanyApi(): UpdateAdminCompanyApiParams {
  return useCallback((params, companyId) => apiClient.put(`companies/${companyId}`, params), []);
}

export function useUpdateAutoRenewStatusOfCompanySubscriptionApi(): UpdateAutoRenewStatusOfCompanySubscriptionApiParams {
  return  useCallback((subscriptionId, autoRenew) => apiClient.put(`subscriptions/${subscriptionId}`, {autoRenew}), []);
}

export function useGetAdminCompanyPhoneNumbersApi(): GetAdminCompanyPhoneNumbersApiParams {
  return useCallback((conpanyId) => apiClient.get(`/companies/${conpanyId}/phone`), []);
}

export function useDeleteAdminCompanyPhoneNumberApi(): DeleteAdminCompanyPhoneNumberApiParams {
  return useCallback((companyId, phoneId) => apiClient.delete(`companies/${companyId}/phone/${phoneId}`), []);
}

export function useUpdateAdminCompanyPhoneNumberApi(): UpdateAdminCompanyPhoneNumberApiParams {
  return useCallback((companyId, phoneId, number) => apiClient.put(`companies/${companyId}/phone/${phoneId}`, {number}), []); 
}

export function useCreateAdminCompanyPhoneNumberApi(): CreateAdminCompanyPhoneNumberApiParams {
  return useCallback((companyId, number) => apiClient.post(`companies/${companyId}/phone/`, {number}), []); 
}

