import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetAdminCompanyApi,
  useGetAdminCompanyMetricsApi,
} from "src/api/adminCompanies";
import MainWrapper from "src/components/MainWrapper/view";
import { AdminCompany, Keyword, Metric } from "src/constants/types";
import { selectedCompany } from "src/store/company/companySlice";
import { selectProfile } from "src/store/profile/profileSlice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import dayjs, { Dayjs } from "dayjs";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import CreateKeywordModal from "../Keywords/CreateKeywordModal/view";
import toast from "react-hot-toast";
import CreateCampaignModal from "../Campaigns/CreateCampaignModal/view";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import MetricCard from "./MetricCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetKeywordsApi } from "src/api/keywords";
import { useGetContactsApi, useGetGroupsApi } from "src/api/contacts";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

const enum TimePeriod {
  LAST7DAYS = "last7days",
  LAST30DAYS = "last30days",
  LAST90DAYS = "last90days",
  LAST365DAYS = "last365days",
  CUSTOM = "custom",
}

function Home() {
  const navigate = useNavigate();
  const [company, setCompany] = useState<AdminCompany>();
  useHandleSelectedCompany();
  const { profile } = useSelector(selectProfile);
  const selectedCompanyId = useSelector(selectedCompany);
  const getCompany = useGetAdminCompanyApi();
  const getCompanyMetrics = useGetAdminCompanyMetricsApi();
  const getKeywords = useGetKeywordsApi();
  const getGroups = useGetGroupsApi();
  const getContacts = useGetContactsApi();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showCreateKeywordModal, setCreateKeywordModal] = useState(false);
  const [showCreateCampaignModal, setCreateCampaignModal] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [editableKeyword] = useState<Keyword | undefined>();
  const [companyLoading, setCompanyLoading] = useState<boolean>(false);
  const [metricsLoading, setMetricsLoading] = useState<boolean>(false);

  const refreshKeywords = () => {
    toast.success("keyword is successfully created");
  };

  const [timePeriod, setTimePeriod] = useState<TimePeriod>(
    TimePeriod.LAST7DAYS
  );
  const [metrics, setMetrics] = useState<Metric>();
  const [keywordCount, setKeywordCount] = useState<number>();
  const [contactsCount, setContactsCount] = useState<number>();

  const [startDateInterval, setStartDateInterval] = useState<string | null>(
    dayjs(new Date())
      .startOf("day")
      .subtract(7, "days")
      .toISOString()
      .substring(0, 10)
  );
  const [endDateInterval, setEndDateInterval] = useState<string | null>(null);

  function getMetrics() {
    if (!selectedCompanyId || !startDateInterval || !endDateInterval) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: any[] = [];

    promises.push(
      getCompany(selectedCompanyId).then((response) => {
        setCompany(response.data);
        setCompanyLoading(false);
      })
    );

    promises.push(
      getGroups({
        companyId: selectedCompanyId!,
        offset: 0,
        limit: 10000,
      }).then((data) => {
        const defaultGroup = data.data.items.find((g) => g.type === "default");
        if (defaultGroup) {
          getContacts({
            companyId: selectedCompanyId,
            limit: 1,
            groups: [defaultGroup.id].toString(),
            offset: 0,
          }).then((response) => {
            setContactsCount(response.data.count);
          });
        }
      })
    );

    promises.push(
      getKeywords({
        companyId: selectedCompanyId,
        limit: 1,
        offset: 0,
      }).then((response) => {
        setKeywordCount(response.data.count);
      })
    );

    promises.push(
      getCompanyMetrics({
        companyIds: selectedCompanyId,
        startDateInterval: startDateInterval,
        endDateInterval: endDateInterval,
      }).then((response) => {
        setMetrics(response.data[0]);
      })
    );

    Promise.all(promises).finally(() => {
      setMetricsLoading(false);
    });
  }

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    getMetrics();
  }, [startDateInterval, endDateInterval]);

  useEffect(() => {
    switch (timePeriod) {
      case TimePeriod.LAST7DAYS: {
        setStartDateInterval(
          dayjs(new Date())
            .startOf("day")
            .subtract(7, "days")
            .toISOString()
            .substring(0, 10)
        );
        setEndDateInterval(
          dayjs(new Date()).startOf("day").toISOString().substring(0, 10)
        );
        setShowDatePicker(false);
        break;
      }
      case TimePeriod.LAST30DAYS: {
        setStartDateInterval(
          dayjs(new Date())
            .startOf("day")
            .subtract(30, "days")
            .toISOString()
            .substring(0, 10)
        );
        setEndDateInterval(
          dayjs(new Date()).startOf("day").toISOString().substring(0, 10)
        );
        setShowDatePicker(false);
        break;
      }
      case TimePeriod.LAST90DAYS: {
        setStartDateInterval(
          dayjs(new Date())
            .startOf("day")
            .subtract(90, "days")
            .toISOString()
            .substring(0, 10)
        );
        setEndDateInterval(
          dayjs(new Date()).startOf("day").toISOString().substring(0, 10)
        );
        setShowDatePicker(false);
        break;
      }
      case TimePeriod.LAST365DAYS: {
        setStartDateInterval(
          dayjs(new Date())
            .startOf("day")
            .subtract(365, "days")
            .toISOString()
            .substring(0, 10)
        );
        setEndDateInterval(
          dayjs(new Date()).startOf("day").toISOString().substring(0, 10)
        );
        setShowDatePicker(false);
        break;
      }
      case TimePeriod.CUSTOM: {
        setStartDateInterval(null);
        setEndDateInterval(null);
        setShowDatePicker(true);
        break;
      }
    }
  }, [timePeriod]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    setCompanyLoading(true);
    getMetrics();
  }, [selectedCompanyId]);

  const creditsInPercents =
    company?.subscription?.creditsRemaining &&
    company?.subscription?.plan?.credits
      ? (100 * company?.subscription?.creditsRemaining) /
        company?.subscription?.plan?.credits
      : 0;

  const formatTimePeriodButtonText = () => {
    switch (timePeriod) {
      case TimePeriod.LAST7DAYS: {
        return "Last 7 days";
      }
      case TimePeriod.LAST30DAYS: {
        return "Last 30 days";
      }
      case TimePeriod.LAST90DAYS: {
        return "Last 90 days";
      }
      case TimePeriod.LAST365DAYS: {
        return "Last 365 days";
      }
      case TimePeriod.CUSTOM: {
        return "Custom";
      }
      default: {
        return "Time Period";
      }
    }
  };

  return (
    <MainWrapper>
      <Box>
        {profile && (
          <Typography>
            Hi <Box sx={{ display: "inline-block" }}>{profile?.firstName}</Box>
          </Typography>
        )}
      </Box>
      <Stack direction="row">
        <Card
          variant="outlined"
          sx={{ marginTop: "20px", width: "75%", minHeight: "100px" }}
        >
          <CardContent sx={{ minHeight: "100px" }}>
            {companyLoading && (
              <Box width="100%" height="100%">
                <CircularProgress />
              </Box>
            )}
            {!companyLoading &&
              company?.subscription &&
              company.subscription.plan && (
                <Box>
                  <Box sx={{ fontWeight: "bold", display: "inline-block" }}>
                    {company?.subscription?.plan?.name}:
                  </Box>{" "}
                  {dayjs(company?.subscription?.startDate).format("YYYY/MM/DD")}{" "}
                  - {dayjs(company?.subscription?.endDate).format("YYYY/MM/DD")}
                  <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <BorderLinearProgress
                      sx={{ height: "20px" }}
                      variant="determinate"
                      value={creditsInPercents}
                    />
                  </Box>
                  <Stack
                    direction="row"
                    sx={{ height: "50px", marginTop: "20px" }}
                  >
                    <Box sx={{ width: "33%" }}>
                      <Box sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                        {company.subscription.creditsRemaining?.toLocaleString() ||
                          0}
                      </Box>
                      <Box>Credits Available</Box>
                    </Box>
                    <Divider
                      sx={{ marginLeft: "10px", marginRight: "10px" }}
                      orientation="vertical"
                    ></Divider>
                    <Box sx={{ width: "33%" }}>
                      <Box sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                        {(
                          company.subscription.plan.credits -
                          (company.subscription.creditsRemaining || 0)
                        ).toLocaleString() || 0}
                      </Box>
                      <Box>Used</Box>
                    </Box>
                    <Divider
                      sx={{ marginLeft: "10px", marginRight: "10px" }}
                      orientation="vertical"
                    ></Divider>
                    <Box sx={{ width: "33%" }}>
                      <Box sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                        {company.subscription.plan?.credits.toLocaleString() ||
                          0}
                      </Box>
                      <Box>In Plan</Box>
                    </Box>
                  </Stack>
                </Box>
              )}
            {!company?.subscription && !companyLoading && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100%", minHeight: "100px" }}
              >
                There are no active subscriptions
              </Box>
            )}
          </CardContent>
        </Card>
        <Box sx={{ width: "1%" }}></Box>
        <Card variant="outlined" sx={{ marginTop: "20px", width: "24%" }}>
          <CardContent>
            <Stack direction="column" justifyContent="center">
              <Button
                size="small"
                onClick={() => {
                  setCreateCampaignModal(true);
                }}
                sx={{ marginBottom: "10px", marginTop: "10px" }}
                variant="contained"
              >
                Create Campaign
              </Button>
              <Button
                size="small"
                sx={{ marginBottom: "10px" }}
                onClick={() => {
                  setCreateKeywordModal(true);
                }}
                variant="contained"
              >
                Add Keyword
              </Button>
              <Button
                size="small"
                onClick={() => navigate("/groups")}
                variant="contained"
              >
                Manage Contacts
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Box sx={{ marginTop: "30px" }}>
        <Stack direction="row">
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            size="small"
            disableElevation
            onClick={handleClick}
            sx={{ marginBottom: "10px" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {formatTimePeriodButtonText()}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setTimePeriod(TimePeriod.LAST7DAYS);
                handleClose();
              }}
            >
              Last 7 days
            </MenuItem>
            <MenuItem
              onClick={() => {
                setTimePeriod(TimePeriod.LAST30DAYS);
                handleClose();
              }}
            >
              Last 30 days
            </MenuItem>
            <MenuItem
              onClick={() => {
                setTimePeriod(TimePeriod.LAST90DAYS);
                handleClose();
              }}
            >
              Last 90 days
            </MenuItem>
            <MenuItem
              onClick={() => {
                setTimePeriod(TimePeriod.LAST365DAYS);
                handleClose();
              }}
            >
              Last 365 days
            </MenuItem>
            <MenuItem
              onClick={() => {
                setTimePeriod(TimePeriod.CUSTOM);
                handleClose();
              }}
            >
              Custom
            </MenuItem>
          </Menu>
          {showDatePicker && (
            <Stack direction="row" gap="10px">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ paddingTop: 0 }}
                >
                  <DatePicker
                    sx={{
                      "& .MuiStack-root": { paddingTop: 0 },
                      "& .MuiOutlinedInput-root": {
                        height: "32px",
                        marginRight: 0,
                        marginLeft: "10px",
                      },
                      overflow: "hidden",
                    }}
                    maxDate={dayjs(endDateInterval || new Date())}
                    format="YYYY-MM-DD"
                    closeOnSelect={true}
                    onChange={(value: Dayjs) =>
                      setStartDateInterval(value.format("YYYY-MM-DD"))
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ paddingTop: 0 }}
                >
                  <DatePicker
                    sx={{
                      "& .MuiStack-root": { paddingTop: 0 },
                      "& .MuiOutlinedInput-root": {
                        height: "32px",
                        marginRight: 0,
                      },
                      overflow: "hidden",
                    }}
                    maxDate={dayjs(new Date())}
                    format="YYYY-MM-DD"
                    closeOnSelect={true}
                    onChange={(value) =>
                      setEndDateInterval(value ? value.format("YYYY-MM-DD") : null)
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>
          )}
        </Stack>
        {metricsLoading && (
          <Stack direction="row">
            <CircularProgress />
          </Stack>
        )}
        {!metricsLoading && metrics && (
          <Stack direction="row">
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={3}>
                  <MetricCard
                    title="Inbound Credits"
                    value={metrics.inboundCredits}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MetricCard
                    title="Outbound Credits"
                    value={metrics.outboundCredits}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MetricCard
                    title=" SMS Message Count"
                    value={metrics.smsCount}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MetricCard
                    title="MMS Message Count"
                    value={metrics.mmsCount}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MetricCard
                    title="Campaign Messages"
                    value={metrics.campaignMessagesCount}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MetricCard
                    title="Inbox Messages"
                    value={metrics.inboxMessagesCount}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MetricCard
                    title="Total Contacts"
                    value={contactsCount || ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MetricCard
                    title="Total Keywords"
                    value={keywordCount || ""}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        )}
      </Box>
      <CreateKeywordModal
        show={showCreateKeywordModal}
        setShowModal={setCreateKeywordModal}
        keywordData={editableKeyword}
        refreshKeywords={refreshKeywords}
      />
      <CreateCampaignModal
        show={showCreateCampaignModal}
        setShowModal={setCreateCampaignModal}
      />
    </MainWrapper>
  );
}

export default Home;
