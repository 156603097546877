import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdminCompany, Plan, Subscription } from "src/constants/types";
import {
  useCreateAdminCompanySubscriptionApi,
  useDeleteAdminCompanySubscriptionApi,
  useGetAdminPlansApi,
  useGetAdminSubscriptionsApi,
  useUpdateAutoRenewStatusOfCompanySubscriptionApi,
} from "src/api/adminCompanies";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Divider from "@mui/material/Divider";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import toast from "react-hot-toast";

type CompanySubscriptionProps = {
  company: AdminCompany;
};

const CompanySubscriptionTab = (props: CompanySubscriptionProps) => {
  const { company } = props;
  const getAdminPlans = useGetAdminPlansApi();
  const getSubscriptions = useGetAdminSubscriptionsApi();
  const createSubscription = useCreateAdminCompanySubscriptionApi();
  const updateAutoRenew = useUpdateAutoRenewStatusOfCompanySubscriptionApi();
  const deleteSubscription = useDeleteAdminCompanySubscriptionApi();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([
    {
      id: "",
      startDate: "",
      endDate: "",
      autoRenew: true,
      company: company,
    },
  ]);
  const [loading, setLoading] = useState<boolean>(true);
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const [plans, setPlans] = useState<Plan[]>();

  useEffect(() => {
    const params = {
      companyIds: company.id,
      startDateInterval: dayjs(new Date()).toISOString(),
      limit: 10000,
      offset: 0,
    };
    getAdminPlans({
      limit: 10000,
      offset: 0,
      applicationId: company.application.id,
      types: 'public,private',
    }).then((response) => {
      if (response.data.items) {
        setPlans(response.data.items);
      }
    });
    getSubscriptions(params)
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          setSubscriptions(response.data.items.reverse());
        } else {
          setSubscriptions([
            {
              id: "",
              startDate: "",
              endDate: "",
              autoRenew: true,
              company: company,
            },
          ]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const validate = () => {
    if (subscriptions?.some((s) => !s.startDate || !s.plan)) {
      return false;
    }

    return true;
  };

  const handleAddNewSubscription = () => {
    setSubscriptions([
      ...subscriptions,
      {
        id: "",
        startDate: "",
        endDate: "",
        autoRenew: true,
        company: company,
      },
    ]);
  };

  const handleSubscriptionAutoRenewChange = (
    id: string,
    autoRenew: boolean
  ) => {
    const modifiedSubscriptions = subscriptions.map((s) => {
      return {
        ...s,
        autoRenew: s.id === id ? autoRenew : s.autoRenew,
      };
    });
    setSubscriptions(modifiedSubscriptions);
  };

  const handleSubscriptionStartDateChange = (id: string, date: dayjs.Dayjs) => {
    const modifiedSubscriptions = subscriptions.map((s) => {
      return {
        ...s,
        startDate: s.id === id ? date.format('YYYY-MM-DD') : s.startDate,
      };
    });

    setSubscriptions(modifiedSubscriptions);
  };

  const handleChangeSubscriptionPlan = (id: string, planId: string) => {
    const newPlan = plans?.find((a) => a.id === planId);
    if (newPlan) {
      const modifiedSubscriptions = subscriptions.map((s) => {
        return {
          ...s,
          plan: s.id === id ? newPlan : s.plan,
          startDate: s.id === id ? "" : s.startDate,
        };
      });

      setSubscriptions(modifiedSubscriptions);
    }
  };

  const isValidForm = () => {
    if (subscriptions?.some((s) => !s.startDate || !s.plan)) {
      return false;
    }

    return true;
  };

  const handleAdminCompanySubscriptionSave = () => {
    const isValid = validate();
    if (isValid) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: any[] = [];
      const newSubscription = subscriptions.find(
        (s) => s.id === "" && s.plan && s.plan.id
      );
      if (newSubscription) {
        subscriptions.forEach((s) => {
          if (s.id) {
            promises.push(updateAutoRenew(s.id, false));
          }
        });

        Promise.all(promises).then(() => {
          createSubscription(
            company.id,
            newSubscription.plan!.id,
            newSubscription.startDate,
            newSubscription.autoRenew
          ).then(() => {
            toast("subscription is created");
            const params = {
              companyIds: company.id,
              startDateInterval: dayjs(new Date().setHours(0,0,0,0)).toISOString(),
              limit: 10000,
              offset: 0,
            };
            setLoading(true);
            getSubscriptions(params)
              .then((response) => {
                if (response.data.items && response.data.items.length > 0) {
                  setSubscriptions(response.data.items.reverse());
                }
              })
              .finally(() => {
                setLoading(false);
              });
          });
        });
      } else {
        subscriptions.forEach((s) => {
          if (s.id) {
            promises.push(updateAutoRenew(s.id, s.autoRenew));
          }
          Promise.all(promises).finally(() => {
            toast("plans are updated", {
              id: "subscription",
            });
          });
        });
      }
    }
  };

  return (
    <Box sx={{ marginTop: "20px" }}>
      {!loading && (
        <Box>
          {subscriptions &&
            subscriptions.map((s, index) => (
              <Stack
                key={s.id}
                direction="column"
                width="100%"
                alignItems="center"
                sx={{ marginTop: "20px", paddingTop: 0 }}
              >
                <Stack
                  direction="row"
                  width="100%"
                  gap="5px"
                  sx={{ paddingTop: 0 }}
                >
                  <FormControl sx={{ width: "49%" }}>
                    <InputLabel>Plan</InputLabel>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      value={s.plan?.id}
                      label={"Plan"}
                      placeholder="Plan"
                      onChange={(e) => {
                        handleChangeSubscriptionPlan(s.id, e.target.value);
                      }}
                    >
                      {plans &&
                        plans
                          .sort((a, b) =>
                            a.name
                              .toLowerCase()
                              .localeCompare(b.name.toLowerCase())
                          )
                          .map((c) => (
                            <MenuItem value={c.id} key={c.id}>
                              {c.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ paddingTop: 0 }}
                    >
                      <DatePicker
                        sx={{
                          "& .MuiStack-root": { paddingTop: 0 },
                          "& .MuiOutlinedInput-root": {
                            height: "55px",
                            marginRight: 0,
                          },
                          overflow: "hidden",
                        }}
                        minDate={dayjs(
                          index === 0
                            ? s.id
                              ? s.startDate
                              : new Date()
                            : subscriptions[index - 1].endDate
                        )}
                        format="YYYY-MM-DD"
                        disabled={!!s.id}
                        closeOnSelect={false}
                        onChange={(value) =>
                          handleSubscriptionStartDateChange(s.id, value!)
                        }
                        value={s.startDate ? dayjs(s.startDate.substring(0, 10)) : null}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      openDialog({
                        title: `Delete current plan`,
                        content: "Are you sure you want to delete this plan?",
                        onConfirm: () => {
                          if (s.id === "") {
                            setSubscriptions(subscriptions.filter((s) => s.id));
                          } else {
                            deleteSubscription(s.id).then(() => {
                              toast("Plan is deleted");
                              setSubscriptions(
                                subscriptions.filter(
                                  (subscription) => subscription.id !== s.id
                                )
                              );
                            });
                          }
                        },
                      });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Stack>
                {s.id && (
                  <Box display="flex" width="100%" sx={{ marginTop: "20px" }}>
                    End Date:{" "}
                    {s.endDate && dayjs(s.endDate.substring(0, 10)).format("YYYY-MM-DD")}
                  </Box>
                )}
                <Box display="flex" width="100%" sx={{ marginTop: "20px" }}>
                  <Box sx={{ marginRight: "10px" }}>Auto Renew:</Box>
                  <FormControl>
                    <ToggleButtonGroup
                      sx={{ height: "25px", zIndex: "999" }}
                      size="small"
                      color="primary"
                      value={s.autoRenew}
                      exclusive
                      onChange={() => {
                        handleSubscriptionAutoRenewChange(s.id, !s.autoRenew);
                      }}
                    >
                      <ToggleButton value={false} selected={!s.autoRenew}>
                        Off
                      </ToggleButton>
                      <ToggleButton value={true} selected={s.autoRenew}>
                        On
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                </Box>
              </Stack>
            ))}
          {subscriptions.filter((s) => s.id === "").length === 0 && (
            <Box sx={{ marginTop: "20px" }}>
              <Button
                size="small"
                variant="contained"
                onClick={handleAddNewSubscription}
              >
                Add New Plan
              </Button>
            </Box>
          )}

          <Divider sx={{ width: "100%", marginTop: "20px" }} />
          <Stack direction="row" justifyContent="space-between">
            <Button
              size="small"
              disabled={!isValidForm()}
              variant="contained"
              style={{ width: "150px", marginTop: "20px" }}
              onClick={handleAdminCompanySubscriptionSave}
            >
              Save
            </Button>
          </Stack>
        </Box>
      )}
      {loading && <CircularProgress />}
      <ConfirmationDialog />
    </Box>
  );
};

export default CompanySubscriptionTab;
