import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

type MetricProps = {
  title: string;
  value: string | number;
};

function MetricCard(props: MetricProps) {
  const { title, value } = props;

  return (
    <Card>
      <CardContent>
        <Typography sx={{marginBottom: '10px'}}>{title}</Typography>
        <Box sx={{display: 'inline-block'}} fontWeight='bold'>{value}</Box>
      </CardContent>
    </Card>
  );
}

export default MetricCard;
